import React, { useEffect } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Import Navigate for redirection
import { Provider, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import store from './redux/store';
import { fetchPicturesRequest } from './redux/pictures/actions';

import FilteredImages from './components/FilteredImages';
import FilteredImagesHome from './components/FilteredImagesHome';
import Random from './pages/Random';
import Places from './pages/Places';
import Collections from './pages/Collections';
import Layout from './components/Layout';
import ScrollToTop from './components/helpers/ScrollToTop';
import ProFilter from './components/ProFilter/ProFilter';

const AppInitializer = () => {
  const dispatch = useDispatch();
  ReactGA.initialize('G-CC6TGR81NX');

  useEffect(() => {
    // Dispatch the fetchPicturesRequest action only once when the app starts
    dispatch(fetchPicturesRequest());
  }, [dispatch]);

  const cityTags = ['munich', 'berlin', 'freiburg', 'hamburg'];
  const natureTags = ['nature', 'landscape', 'outdoor', 'mountain', 'forest'];
  const placesTags = ["oktoberfest", "tollwood", "breeze"];
  const dogsTags = ["dog", "animal"];

  const collectionRoutes = [
    { title: 'Night', path: 'night', tags: ['night'], featured: true },
    { title: 'Sun', path: 'sun', tags: ['sun'], featured: true },
    { title: 'Oktoberfest', path: 'oktoberfest', tags: ['oktoberfest'] },
    { title: 'Skyline', path: 'skyline', tags: ['skyline'], featured: true },
    { title: 'Allianz Arena', path: 'allianzarena', tags: ['allianz arena'] },
    { title: 'Olympic Stadium & Park', path: 'olympicpark', tags: ['olympiastadion', 'olympia'], featured: true },
    { title: 'Water', path: 'water', tags: ['isar', 'lake'], featured: true },
    { title: 'Details', path: 'details', tags: ['macro'] },
    { title: 'Snow', path: 'snow', tags: ['snow'] },
    { title: 'Public Transport', path: 'publictransport', tags: ['subway'] },
    { title: 'Focus', path: 'focus', tags: ['focus'] },
    { title: 'People', path: 'people', tags: ['people'], featured: true },
    { title: 'Bergson', path: 'bergson', tags: ['bergson'] },
    { title: 'Tollwood', path: 'tollwood', tags: ['tollwood'] },
    { title: 'Food Market', path: 'foodmarket', tags: ['food trucks'] },
    { title: 'Breeze', path: 'breeze', tags: ['breeze'] }
  ];

  const citiesRoutes = [
    { title: 'München', path: 'munich', tags: ['munich'] },
    { title: 'Hamburg', path: 'hamburg', tags: ['hamburg'] },
    { title: 'Berlin', path: 'berlin', tags: ['berlin'] },
    { title: 'Freiburg', path: 'freiburg', tags: ['freiburg'] },
    { title: 'Mixed Places', path: 'mixed', tags: ['amsterdam', 'Bremerhaven'] }
  ];

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<FilteredImagesHome title="Home" />} />
        <Route path="/feed" element={<FilteredImages title="All Images" />} />
        <Route path="/places" element={<Places title="Places" />} />
        <Route path="/places" element={<FilteredImages tags={placesTags} title="Places" />} />
        <Route path="/nature" element={<FilteredImages tags={natureTags} title="Nature" />} />
        <Route path="/dogs" element={<FilteredImages tags={dogsTags} title="Dogs & Friends" />} />
        <Route path="/collections" element={<Collections title="Collections" />} />
        <Route path="/explore" element={<ProFilter />} />

        {collectionRoutes.map((collection) => (
          <Route
            key={collection.path}
            path={`/collections/${collection.path}`}
            element={<FilteredImages tags={collection.tags} title={collection.title} />}
          />
        ))}

        {citiesRoutes.map((collection) => (
          <Route
            key={collection.path}
            path={`/places/${collection.path}`}
            element={<FilteredImages tags={collection.tags} title={collection.title} />}
          />
        ))}

        <Route path="/random" element={<Random />} />
        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <Helmet>
          <title>supernice pictures</title>
          <meta name="description" content="This is a description of my page." />
        </Helmet>
        <Router>
          <ScrollToTop />
          <AppInitializer /> {/* Initialize the app and dispatch fetchPicturesRequest */}
        </Router>
      </HelmetProvider>
    </Provider>
  );
};

export default App;
