import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import '../Home.css';

// Function to sort the image data by DateTimeOriginal (newest first)
const sortByDateTimeOriginalDesc = (data) => {
  return data ? data.sort((a, b) => b.data.DateTimeOriginal - a.data.DateTimeOriginal) : [];
};

const Collections = ({ tags = [], title = "Places" }) => {
  const [filteredImages, setFilteredImages] = useState([]);

  // Fetch the images from the Redux store
  const pictures = useSelector((state) => state.pictures.pictures || []);

  // Filter the images based on the tags passed as props
  useEffect(() => {
    if (pictures.length > 0) {
      const sortedData = sortByDateTimeOriginalDesc(pictures);
      
      // If no tags are provided, show all images. Otherwise, filter based on tags.
      const filteredData = tags.length === 0 
        ? sortedData 
        : sortedData.filter((item) => {
            return (
              item.Tags &&
              tags.some((tag) => item.Tags.toLowerCase().includes(tag.toLowerCase()))
            );
          });

      setFilteredImages(filteredData);
    }
  }, [pictures, tags]);

  const collections = [
    { title: 'München', path: 'munich', tags: ['munich'], featured: true },
    { title: 'Hamburg', path: 'hamburg', tags: ['hamburg'], featured: true },
    { title: 'Berlin', path: 'berlin', tags: ['berlin'] },
    { title: 'Freiburg', path: 'freiburg', tags: ['freiburg'] },
    { title: 'Mixed Places', path: 'mixed', tags: ['amsterdam', 'Bremerhaven', 'lüneburg'], featured: true }
  ];

  // Split collections based on the featured key
  const featuredCollections = collections.filter(collection => collection.featured);
  const otherCollections = collections.filter(collection => !collection.featured);

  // Function to find the first image that matches the collection tags
  const findImageForCollection = (collectionTags) => {
    return pictures.find((picture) =>
      picture.Tags &&
      collectionTags.some((tag) => picture.Tags.toLowerCase().includes(tag.toLowerCase()))
    );
  };

  return (
    <div className="App">
      <Helmet>
        <title>{title} | supernice pictures</title>
        <meta name="description" content={`Pictures related to ${tags.join(', ')}`} />
      </Helmet>

      <div className="page-nav">
        <h2>{title}</h2>
      </div>

      {/* Featured Places */}
      <div className="flex-container collection-container featured-collection-container-places">
        {/* Display collections with featured: true */}
        {featuredCollections.map((collection, index) => {
          const matchedImage = findImageForCollection(collection.tags);

          return (
            <Link to={`/places/${collection.path}`} key={index} className="collection-item">
              <h3>{collection.title}</h3>
              {matchedImage ? (
                <LazyLoadImage
                  src={`https://supernice-resized.s3.eu-central-1.amazonaws.com/small/${matchedImage.filename}`}
                  alt={collection.title}
                  loading="lazy"
                  threshold="700"
                  type="image"
                />
              ) : (
                <p>No image available</p>
              )}
            </Link>
          );
        })}
      </div>

      {/* Other Places */}
      <div className="flex-container collection-container">
        {/* Display collections with featured: false */}
        {otherCollections.map((collection, index) => {
          const matchedImage = findImageForCollection(collection.tags);

          return (
            <Link to={`/places/${collection.path}`} key={index} className="collection-item">
              <h3>{collection.title}</h3>
              {matchedImage ? (
                <LazyLoadImage
                  src={`https://supernice-resized.s3.eu-central-1.amazonaws.com/small/${matchedImage.filename}`}
                  alt={collection.title}
                  loading="lazy"
                  threshold="700"
                  type="image"
                />
              ) : (
                <p>No image available</p>
              )}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Collections;
