import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import FsLightbox from 'fslightbox-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { usePictures } from '../hooks/usePictures';  // Import your custom hook
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import '../Home.css';

// Function to shuffle an array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Random = () => {
  const [shuffledImages, setShuffledImages] = useState([]);
  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(1);

  // Use the hook to get the images from Redux state
  const { pictures, loading, error } = usePictures();
  const navigate = useNavigate(); // Create a navigate function using useNavigate

  // Shuffle images when pictures are available
  useEffect(() => {
    if (pictures.length > 0) {
      const shuffledData = shuffleArray([...pictures]);  // Shuffle the images
      setShuffledImages(shuffledData);
      setToggler(true);  // Open the lightbox after images are shuffled
    }
  }, [pictures]);

  // Show loading or error if necessary
  if (loading) return <div>Loading pictures...</div>;
  if (error) return <div>Error: {error}</div>;

  // Function to handle lightbox close and navigate back to the previous location
  const handleLightboxClose = () => {
    navigate(-1);  // Navigate back to the previous location
  };

  return (
    <div className="App">
      <Helmet>
        <title>Random | supernice pictures</title>
        <meta name="description" content="This is a description of my page." />
      </Helmet>
      <FsLightbox
        toggler={toggler}
        sources={shuffledImages.map(({ filename }) => `https://supernice-resized.s3.eu-central-1.amazonaws.com/large/${filename}`)}
        slide={productIndex}
        onClose={handleLightboxClose}  // Call handleLightboxClose when the lightbox is closed
      />
    </div>
  );
};

export default Random;
