import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  FETCH_PICTURES_REQUEST,
  FETCH_PICTURES_SUCCESS,
  FETCH_PICTURES_FAILURE,
} from './actions';

const jsonUrl = 'https://supernice-resized.s3.eu-central-1.amazonaws.com/pictures.json';

// Saga to handle fetching pictures
function* fetchPicturesSaga() {
  try {
    const response = yield call(axios.get, jsonUrl, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    yield put({ type: FETCH_PICTURES_SUCCESS, payload: response.data }); // Dispatch success action with data
  } catch (error) {
    yield put({ type: FETCH_PICTURES_FAILURE, payload: error.message }); // Dispatch failure action on error
  }
}

// Watcher saga
export function* watchFetchPictures() {
  yield takeLatest(FETCH_PICTURES_REQUEST, fetchPicturesSaga);
}
