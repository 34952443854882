import React, { useEffect, useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Content = ({ ProFilter, toggler, setToggler, productIndex, setProductIndex }) => {
  
  const handleClick = (id) => {
    setProductIndex(id);
    setToggler(prev => !prev);
  };

  // Generate a unique key whenever ProFilter changes to rebuild the lightbox
  const [lightboxKey, setLightboxKey] = useState(0);

  useEffect(() => {
    setLightboxKey(prevKey => prevKey + 1);
  }, [ProFilter]);

  return (
    <div className="content-wrapper">
      <div className="page-nav">
        <h2>Explore Images</h2>
      </div>
      <div className="filter-result-container">
        {ProFilter.length === 0 ? (
          <p>No images available.</p>
        ) : (
          ProFilter.map(({ filename }, index) => (
            <div className="item" key={index} onClick={() => handleClick(index + 1)}>
              <LazyLoadImage
                src={`https://supernice-resized.s3.eu-central-1.amazonaws.com/small/${filename}`}
                alt={`Image ${filename}`}
                loading="lazy"
                threshold="700"
                type="image" />
            </div>
          ))
        )}

        <FsLightbox
          key={lightboxKey} // Force rebuild of the lightbox
          toggler={toggler}
          sources={ProFilter.map(({ filename }) => `https://supernice-resized.s3.eu-central-1.amazonaws.com/large/${filename}`)}
          slide={productIndex}
          exitFullscreenOnClose={true} />
      </div>
    </div>
  );
};

export default Content;
