import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import FsLightbox from 'fslightbox-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import '../Home.css';

import { translateCamera } from './helpers/TranslateCamera';

// Function to sort the image data by DateTimeOriginal (newest first)
const sortByDateTimeOriginalDesc = (data) => {
  return data ? data.sort((a, b) => b.data.DateTimeOriginal - a.data.DateTimeOriginal) : [];
};

const FilteredImages = ({ tags = [], title = "" }) => {
  const [filteredImages, setFilteredImages] = useState([]);
  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(1);
  const [imageSize, setImageSize] = useState('small'); // State to store selected image size

  // Fetch the images from the Redux store
  const pictures = useSelector((state) => state.pictures.pictures || []);

  // Filter the images based on the tags passed as props
  useEffect(() => {
    if (pictures.length > 0) {
      const sortedData = sortByDateTimeOriginalDesc(pictures);

      // If no tags are provided, show all images. Otherwise, filter based on tags.
      const filteredData = tags.length === 0 
        ? sortedData 
        : sortedData.filter((item) => {
            return (
              item.Tags &&
              tags.some((tag) => item.Tags.toLowerCase().includes(tag.toLowerCase()))
            );
          });

      setFilteredImages(filteredData);
    }
  }, [pictures, tags]);

  // Automatically set the image size based on the number of filtered images
  useEffect(() => {
    if (filteredImages.length > 0) {
      if (filteredImages.length <= 8) {
        setImageSize('large');
      } else if (filteredImages.length <= 20) {
        setImageSize('medium');
      } else {
        setImageSize('small');
      }
    }
  }, [filteredImages]);

  // Reset the image size when the component is unmounted
  useEffect(() => {
    return () => {
      setImageSize('small');
    };
  }, []);

  const handleClick = (id) => {
    setProductIndex(id);
    setToggler((prev) => !prev);
  };

  // Handle change of image size manually via dropdown
  const handleImageSizeChange = (event) => {
    setImageSize(event.target.value);
  };

  // Determine the correct image path based on the selected image size
  const getImagePath = (size, filename) => {
    if (size === 'large') {
      return `https://supernice-resized.s3.eu-central-1.amazonaws.com/medium/${filename}`;
    } else if (size === 'full') {
      return `https://supernice-resized.s3.eu-central-1.amazonaws.com/large/${filename}`;
    } else {
      return `https://supernice-resized.s3.eu-central-1.amazonaws.com/small/${filename}`;
    }
  };

  return (
    <div className="App">
      <Helmet>
        <title>{title} | supernice pictures</title>
        <meta name="description" content={`Pictures related to ${tags.join(', ')}`} />
      </Helmet>

      {filteredImages.length > 0 && (
        <div className="featured-item" onClick={() => handleClick(1)}>
        <LazyLoadImage
          src={`https://supernice-resized.s3.eu-central-1.amazonaws.com/large/${filteredImages[0].filename}`} // Always uses "large" path
          alt={`Featured Image ${filteredImages[0].filename}`}
          loading="lazy"
          threshold="700"
          type="image"
        />
      </div>
      )}

      <div className="page-nav">
        <h2>{title}</h2>

        {/* Material UI Select for image size */}
        <FormControl variant="outlined" size="small">
          {/* Custom color for InputLabel */}
          <InputLabel
            id="image-size-select-label"
            sx={{
              color: 'var(--border-color)', // Default label color
              '&.Mui-focused': {
                color: 'var(--text-color)', // Label color when focused
              },
              '&.MuiFormLabel-filled': {
                color: 'var(--border-color)', // Label color when filled
              },
            }}
          >
            Image Size
          </InputLabel>
          <Select
            labelId="image-size-select-label"
            id="image-size-select"
            value={imageSize}
            onChange={handleImageSizeChange}
            label="Image Size"
            sx={{
              color: 'var(--text-color)',         // Text color inside the dropdown
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--border-color)',  // Border color
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--text-color)', // Border color when focused
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--text-color)', // Border color when hovered
              },
              '.MuiSvgIcon-root': {
                color: 'var(--text-color)',       // Dropdown arrow color
              },
            }}
          >
            <MenuItem value="small">Small</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="large">Large</MenuItem>
            <MenuItem value="full">Full</MenuItem> {/* New Full option */}
          </Select>
        </FormControl>
      </div>

      {/* Dynamically apply the "full" class if selected */}
      <div className={`flex-container ${imageSize === 'full' ? 'full' : imageSize}`}>
        {filteredImages.length === 0 ? (
          <p>No images available.</p>
        ) : (
          filteredImages.map(({ filename, Title, data }, index) => (
            <div className="item" key={index} onClick={() => handleClick(index + 1)}>
              <LazyLoadImage
                src={getImagePath(imageSize, filename)}
                alt={`Image ${filename}`}
                loading="lazy"
                threshold="700"
                type="image"
                title={`${translateCamera(data.Model)} ${data.FocalLengthIn35mmFormat}mm f/${data.FNumber}`}
              />
              <div className="info">
                <span>{Title}</span>
                <span>{`${data.FocalLengthIn35mmFormat}mm f/${data.FNumber}`}</span>
              </div>
            </div>
          ))
        )}
      </div>

      <FsLightbox
        toggler={toggler}
        sources={filteredImages.map(({ filename }) => `https://supernice-resized.s3.eu-central-1.amazonaws.com/large/${filename}`)}
        slide={productIndex}
        exitFullscreenOnClose={true}
      />
    </div>
  );
};

export default FilteredImages;
