import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Filters from './Filters';
import Content from './Content';
import { sortByDateTimeOriginalDesc, getSeasonFromMonth, useQuery } from './helpers';
import { cameraOptions, lensOptions, exposureTimeGroups } from './config'; // Import lensOptions
import './ProFilter.css';

const ProFilter = () => {
  const [ProFilter, setProFilter] = useState([]);
  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(1);

  const [cameras, setCameras] = useState([]);
  const [lenses, setLenses] = useState([]);
  const [fNumbers, setFNumbers] = useState([1, 16]);
  const [focalLengths, setFocalLengths] = useState([0, 300]);
  const [years, setYears] = useState([]);  // Ensure years are treated as strings
  const [seasons, setSeasons] = useState([]);
  const [selectedExposureTimes, setSelectedExposureTimes] = useState([]);

  const [uniqueCameras, setUniqueCameras] = useState([]);
  const [uniqueLenses, setUniqueLenses] = useState([]); // Add uniqueLenses state
  const [uniqueFocalLengths, setUniqueFocalLengths] = useState([0, 300]);
  const [uniqueFNumbers, setUniqueFNumbers] = useState([1, 16]);
  const [uniqueYears, setUniqueYears] = useState([]);

  const pictures = useSelector((state) => state.pictures.pictures || []);
  const query = useQuery();
  const navigate = useNavigate();

  // Sync filter states with URL params
  useEffect(() => {
    const cameraParam = query.get('camera');
    const lensParam = query.get('lens');
    const fParam = query.get('f');
    const focalParam = query.get('focal');
    const yearParam = query.get('year');
    const seasonParam = query.get('season');
    const exposureParam = query.get('exposure');

    // Ensure no duplicate years in the URL and treat years as strings
    const uniqueYearsFromUrl = yearParam ? [...new Set(yearParam.split(',').map(year => year.toString()))] : [];

    setCameras(cameraParam ? cameraParam.split(',') : []);
    setLenses(lensParam ? lensParam.split(',') : []); // Initialize lenses from URL using the key
    setFNumbers(fParam ? fParam.split(',').map(f => parseFloat(f)) : [1, 16]);
    setFocalLengths(focalParam ? focalParam.split(',').map(focal => parseInt(focal)) : [0, 300]);
    setYears(uniqueYearsFromUrl);  // Store years as strings in state
    setSeasons(seasonParam ? seasonParam.split(',') : []);
    setSelectedExposureTimes(exposureParam ? exposureParam.split(',') : []);
  }, [query]);

  // Filter and set image data
  useEffect(() => {
    if (pictures.length > 0) {
      const sortedData = sortByDateTimeOriginalDesc(pictures);
      const camerasSet = new Set();
      const lensesSet = new Set(); // Add a set for lenses
      const focalLengthsSet = new Set();
      const fNumbersSet = new Set();
      const yearsSet = new Set();

      const filteredData = sortedData.filter((item) => {
        const itemDate = new Date(item.data.DateTimeOriginal * 1000);
        const year = itemDate.getFullYear().toString();  // Ensure year is treated as a string
        const month = itemDate.getMonth() + 1;
        const season = getSeasonFromMonth(month);

        const cameraOption = cameraOptions.find(cam => cam.tag === item.data.Model);
        const cameraKey = cameraOption ? cameraOption.key : item.data.Model;

        // Match lens using the lens key to find the tag
        const lensKey = lensOptions.find(lens => lens.tag === item.data.LensModel)?.key || item.data.LensModel;

        // Adjust aperture filtering to handle "16+"
        const fMatch = fNumbers.length === 0 || 
          (fNumbers[1] >= 16 ? item.data.FNumber >= fNumbers[0] : 
          (item.data.FNumber >= fNumbers[0] && item.data.FNumber <= fNumbers[1]));

        // Adjust focal length filtering to handle "300+"
        const focalMatch = focalLengths.length === 0 || 
          (focalLengths[1] >= 300 ? item.data.FocalLengthIn35mmFormat >= focalLengths[0] : 
          (item.data.FocalLengthIn35mmFormat >= focalLengths[0] && item.data.FocalLengthIn35mmFormat <= focalLengths[1]));

        const cameraMatch = cameras.length === 0 || cameras.includes(cameraKey);
        const lensMatch = lenses.length === 0 || lenses.includes(lensKey); // Check if lens matches the filter by key
        const exposureMatch = selectedExposureTimes.length === 0 || selectedExposureTimes.some(group => {
          const exposureGroup = exposureTimeGroups.find(g => g.key === group);
          return item.data.ExposureTime >= exposureGroup.range[0] && item.data.ExposureTime < exposureGroup.range[1];
        });
        const yearMatch = !years.length || years.includes(year);
        const seasonMatch = !seasons.length || seasons.includes(season);

        if (cameraKey) camerasSet.add(cameraKey);
        if (lensKey) lensesSet.add(lensKey); // Add all lens models to the set, even if they are not in lensOptions
        if (item.data.FocalLengthIn35mmFormat) focalLengthsSet.add(item.data.FocalLengthIn35mmFormat);
        if (item.data.FNumber) fNumbersSet.add(item.data.FNumber);
        yearsSet.add(year);

        return cameraMatch && lensMatch && fMatch && focalMatch && exposureMatch && yearMatch && seasonMatch;
      });

      setProFilter(filteredData);
      setUniqueCameras(Array.from(camerasSet).sort());
      setUniqueLenses(Array.from(lensesSet).sort()); // Set unique lenses, including those not in lensOptions
      setUniqueFocalLengths([Math.min(...focalLengthsSet), Math.max(...focalLengthsSet)]);
      setUniqueFNumbers([Math.min(...fNumbersSet), Math.max(...fNumbersSet)]);
      setUniqueYears(Array.from(yearsSet).sort((a, b) => b - a));
    }
  }, [pictures, cameras, lenses, fNumbers, focalLengths, selectedExposureTimes, years, seasons]);

  // Update the URL when filters change
  const updateUrlWithFilters = (filterKey, filterValue) => {
    const newQuery = new URLSearchParams(query);
    if (filterValue.length > 0) {
      newQuery.set(filterKey, filterValue.join(',')); // Ensure two values are kept in the URL
    } else {
      newQuery.delete(filterKey);
    }
    navigate(`?${newQuery.toString()}`, { replace: true });
  };

  return (
    <div className="App">
      <Helmet>
        <title>Explore Images | supernice pictures</title>
        <meta name="description" content={`Filtered Pictures`} />
      </Helmet>
      <Filters
        cameras={cameras} setCameras={(c) => { setCameras(c); updateUrlWithFilters('camera', c); }}
        lenses={lenses} setLenses={(l) => { setLenses(l); updateUrlWithFilters('lens', l); }} // Add lens filtering by key
        fNumbers={fNumbers} setFNumbers={(f) => { setFNumbers(f); updateUrlWithFilters('f', f); }}
        focalLengths={focalLengths} setFocalLengths={(f) => { setFocalLengths(f); updateUrlWithFilters('focal', f); }}
        years={years} setYears={(y) => { setYears(y); updateUrlWithFilters('year', y); }}
        seasons={seasons} setSeasons={(s) => { setSeasons(s); updateUrlWithFilters('season', s); }}
        selectedExposureTimes={selectedExposureTimes} setSelectedExposureTimes={(e) => { setSelectedExposureTimes(e); updateUrlWithFilters('exposure', e); }}
        uniqueCameras={uniqueCameras}
        uniqueLenses={uniqueLenses} // Add unique lenses to the filters
        uniqueFocalLengths={uniqueFocalLengths}
        uniqueFNumbers={uniqueFNumbers}
        uniqueYears={uniqueYears}
      />

      <Content
        ProFilter={ProFilter}
        toggler={toggler} setToggler={setToggler}
        productIndex={productIndex} setProductIndex={setProductIndex}
      />
    </div>
  );
};

export default ProFilter;
