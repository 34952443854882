import {
  FETCH_PICTURES_REQUEST,
  FETCH_PICTURES_SUCCESS,
  FETCH_PICTURES_FAILURE,
} from './actions';

const initialState = {
  pictures: [],
  loading: false,
  error: null,
};

export const picturesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PICTURES_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_PICTURES_SUCCESS:
      return { ...state, loading: false, pictures: action.payload };
    case FETCH_PICTURES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
