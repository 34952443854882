import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const sortByDateTimeOriginalDesc = (data) => {
  return data ? data.sort((a, b) => b.data.DateTimeOriginal - a.data.DateTimeOriginal) : [];
};

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const getSeasonFromMonth = (month) => {
  if ([3, 4, 5].includes(month)) return 'spring';
  if ([6, 7, 8].includes(month)) return 'summer';
  if ([9, 10, 11].includes(month)) return 'fall';
  return 'winter';
};
