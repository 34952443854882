import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { picturesReducer } from './pictures/reducer';  // Import your pictures reducer
import rootSaga from './rootSaga';  // Import your sagas

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    pictures: picturesReducer,  // Make sure the pictures reducer is correctly registered
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
