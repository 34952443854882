import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import { exposureTimeGroups, focalLengthMarks, marks, lensOptions, cameraOptions } from './config'; // Import lensOptions and cameraOptions

const Filters = ({
  cameras, setCameras,
  lenses, setLenses, // Add lenses props
  fNumbers, setFNumbers,
  focalLengths, setFocalLengths,
  years, setYears,
  seasons, setSeasons,
  selectedExposureTimes, setSelectedExposureTimes,
  uniqueCameras, uniqueLenses, uniqueFocalLengths, uniqueFNumbers, uniqueYears // Add uniqueLenses
}) => {

  // Generic toggle function for checkboxes
  const handleCheckboxChange = (setter, value, currentList) => {
    const updatedList = currentList.includes(value)
      ? currentList.filter(v => v !== value) // Remove the value if it's already selected
      : [...currentList, value]; // Add the value if it's not selected
    setter(updatedList); // Update the state with the new list
  };

  // Group lensOptions by type: fullframe, crop, and iphone
  const fullframeLenses = lensOptions.filter(lens => lens.type === "fullframe");
  const cropLenses = lensOptions.filter(lens => lens.type === "crop");
  const iphoneLenses = lensOptions.filter(lens => lens.type === "iphone");

  // Function to get slider marks, including '300+'
  const getFocalLengthMarks = () => {
    return focalLengthMarks.map(mark => ({
      ...mark,
      label: mark.value >= 300 ? '300+' : mark.label
    }));
  };

  // Function to get sorted cameraOptions and display camera labels
  const getSortedCameraOptions = () => {
    return cameraOptions.map(cameraOption => {
      const isChecked = cameras.includes(cameraOption.key);
      return (
        <div key={cameraOption.key}>
          <label>
            <Checkbox
              checked={isChecked}
              onChange={() => handleCheckboxChange(setCameras, cameraOption.key, cameras)}
            />
            {cameraOption.label} {/* Display camera label */}
          </label>
        </div>
      );
    });
  };

  return (
    <div className='filter-wrapper'>
      {/* Camera Filter */}
      <div className="filter-area">
        <h3>Camera Models</h3>
        {getSortedCameraOptions()} {/* Render sorted camera options */}
      </div>

      {/* Lenses Filter with Subgroups */}
      <div className="filter-area">
        <h3>Lenses</h3>

        {/* Fullframe Lenses Subgroup */}
        <h4>Fullframe Lenses</h4>
        {fullframeLenses.map(lens => (
          <div key={lens.key}>
            <label>
              <Checkbox
                checked={lenses.includes(lens.key)}
                onChange={() => handleCheckboxChange(setLenses, lens.key, lenses)}
              />
              {lens.label}
            </label>
          </div>
        ))}

        {/* Crop Lenses Subgroup */}
        <h4>Crop Lenses</h4>
        {cropLenses.map(lens => (
          <div key={lens.key}>
            <label>
              <Checkbox
                checked={lenses.includes(lens.key)}
                onChange={() => handleCheckboxChange(setLenses, lens.key, lenses)}
              />
              {lens.label}
            </label>
          </div>
        ))}

      </div>

      {/* Focal Length Filter */}
      <div className="filter-area">
        <h3>Focal Length (full-frame)</h3>
        <Box sx={{ width: 300 }}>
          <Slider
            value={focalLengths}
            onChange={(e, newValue) => setFocalLengths(newValue)}
            valueLabelDisplay="auto"
            min={uniqueFocalLengths[0]} // Set the minimum value based on available data
            max={300} // Set the maximum value to 300
            marks={getFocalLengthMarks()} // Use custom marks including '300+'
          />
        </Box>
      </div>

      {/* F-Number (Aperture) Filter */}
      <div className="filter-area">
        <h3>Aperture</h3>
        <Box sx={{ width: 300 }}>
          <Slider
            value={fNumbers}
            onChange={(e, newValue) => setFNumbers(newValue)}
            valueLabelDisplay="auto"
            min={uniqueFNumbers[0]} // Set the minimum value based on available data
            max={16} // Set the maximum value to 16
            marks={marks.map(mark => ({
              ...mark,
              label: mark.value >= 16 ? '16+' : mark.label
            }))}
          />
        </Box>
      </div>

      {/* Exposure Time Filter */}
      <div className="filter-area">
        <h3>Exposure Time</h3>
        {exposureTimeGroups.map(group => (
          <div key={group.key}>
            <label>
              <Checkbox
                checked={selectedExposureTimes.includes(group.key)}
                onChange={() => handleCheckboxChange(setSelectedExposureTimes, group.key, selectedExposureTimes)}
              />
              {group.label}
            </label>
          </div>
        ))}
      </div>

      {/* Season Filter */}
      <div className="filter-area">
        <h3>Season</h3>
        {['spring', 'summer', 'fall', 'winter'].map(season => (
          <div key={season}>
            <label>
              <Checkbox
                checked={seasons.includes(season)}
                onChange={() => handleCheckboxChange(setSeasons, season, seasons)}
              />
              {season.charAt(0).toUpperCase() + season.slice(1)}
            </label>
          </div>
        ))}
      </div>

      {/* Year Filter */}
      <div className="filter-area">
        <h3>Year</h3>
        {uniqueYears.map(year => (
          <div key={year}>
            <label>
              <Checkbox
                checked={years.includes(String(year))}  // Compare years as strings
                onChange={() => handleCheckboxChange(setYears, String(year), years)}
              />
              {year}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Filters;
