export const FETCH_PICTURES_REQUEST = 'FETCH_PICTURES_REQUEST';
export const FETCH_PICTURES_SUCCESS = 'FETCH_PICTURES_SUCCESS';
export const FETCH_PICTURES_FAILURE = 'FETCH_PICTURES_FAILURE';

export const fetchPicturesRequest = () => ({ type: FETCH_PICTURES_REQUEST });
export const fetchPicturesSuccess = (pictures) => ({
  type: FETCH_PICTURES_SUCCESS,
  payload: pictures,
});
export const fetchPicturesFailure = (error) => ({
  type: FETCH_PICTURES_FAILURE,
  payload: error,
});
