import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TuneIcon from '@mui/icons-material/Tune';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { NavLink } from 'react-router-dom';


const HeaderBar = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
  transform: ${({ $isHidden }) =>
    $isHidden ? 'translateY(-100%)' : 'translateY(0)'};
  z-index: 1000;

  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background: transparent;

  @media screen and (max-width: 1199px) {
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    background: transparent;
    transform: none;
    padding: 0 20px;
  }
`;

const HeaderBarBackground = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background: ${({ $backgroundIsHidden }) =>
    $backgroundIsHidden ? 'transparent' : 'var(--navigation-background)'};
  z-index: 999;
  transition: background 0.3s ease-in-out;

  @media screen and (max-width: 899px) {
    display: none;
  }
`;

const Nav = styled.nav`
  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  li {
    margin-left: 20px;
  }
  a {
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 899px) {
    display: none;
    cursor: pointer;
  }
`;

const Logo = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  gap: 5px;

  @media screen and (max-width: 899px) {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
  }

  a {
    font-weight: bold !important;
    border-bottom: none !important;
`;

const Hamburger = styled.div`
  display: none;

  @media screen and (max-width: 899px) {
    display: block;
    cursor: pointer;
    span {
      display: block;
      width: 25px;
      height: 3px;
      background-color: #fff;
      margin: 5px 0;
    }
  }

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: white;
    }
  }
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  flex-direction: column;
  align-items: flex-end;

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: white;
    }
  }
`;

const MobileMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  color: #fff;
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  background-color: rgb(4 4 4 / 30%);

  div {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
  }
`;

const MenuList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 60px 0 0;
  text-align: center;
  gap: 20px;
`;

const MenuItem = styled.li`
  margin-left: 0 !important;
`;

const MenuLink = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  display: flex;
  gap: 5px;

  &.active {
    font-weight: bold; /* Example styling for active state */
    border-bottom: 1px solid #6a6a6a;
  }

  div {
    width: 24px;
    height: 24px;
  }

  svg {
    width: 24px !important;
    height: 24px !important;

    path {
      fill: white;
    }
  }
`;

const MenuItemMobile = styled.li`
  margin: 20px 0;
`;

const MenuLinkMobile = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  font-size: 1.5em;
  
  &:hover {
    text-decoration: underline;
  }

  &.active {
    font-weight: bold; /* Example styling for active state */
    text-decoration: underline;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: white;
    }
  }
`;

const Header = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [backgroundIsHidden, setBackgroundIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // Hide the header only if the user has scrolled more than 50px
    if (currentScrollY > 300 && currentScrollY > lastScrollY) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }

    if (currentScrollY > 100) {
      setBackgroundIsHidden(true)
    }
    else {
      setBackgroundIsHidden(false)
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  // Effect to disable/enable body scroll when menu is toggled
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const HamburgerIcon = () => (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12ZM13 18C13 17.4477 13.4477 17 14 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18Z"
        fill="#000000"
      />
    </svg>
  );

  const CloseIcon = () => (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
        fill="#0F0F0F"
      />
    </svg>
  );

  return (
    <>
      <HeaderBar $isHidden={isHidden}>
        <Logo>
          <MenuLink to="/">supernice pictures</MenuLink>
        </Logo>

        <Nav>
          <MenuList>
            <MenuItem>
              <MenuLink to="/places" end>Places</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to="/nature" end>Nature</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to="/dogs" end>Dogs & Friends</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to="/collections" end>Collections</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to="/explore" end>
                <TuneIcon />
              </MenuLink>
            </MenuItem>
          </MenuList>
        </Nav>

        <Hamburger onClick={toggleMenu}>
          <HamburgerIcon />
        </Hamburger>
      </HeaderBar>

      <HeaderBarBackground $backgroundIsHidden={backgroundIsHidden} />

        {/* Mobile menu */}
        <MobileMenuWrapper $isOpen={isOpen}>
          <CloseButton onClick={toggleMenu}>
            <CloseIcon />
          </CloseButton>
          <MenuList>
            <MenuItemMobile>
              <MenuLinkMobile to="/" end onClick={toggleMenu}>
                Home
              </MenuLinkMobile>
            </MenuItemMobile>
            <MenuItemMobile>
              <MenuLinkMobile to="/places" end onClick={toggleMenu}>
                Places
              </MenuLinkMobile>
            </MenuItemMobile>
            <MenuItemMobile>
              <MenuLinkMobile to="/nature" end onClick={toggleMenu}>
                Nature
              </MenuLinkMobile>
            </MenuItemMobile>
            <MenuItemMobile>
              <MenuLinkMobile to="/dogs" end onClick={toggleMenu}>
                Dogs & Friends
              </MenuLinkMobile>
            </MenuItemMobile>
            <MenuItemMobile>
              <MenuLinkMobile to="/collections" end onClick={toggleMenu}>
                Collections
              </MenuLinkMobile>
            </MenuItemMobile>
          </MenuList>
        </MobileMenuWrapper>
    </>
  );
};

export default Header;
