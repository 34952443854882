export const cameraOptions = [
    { key: "a7ii", label: "Sony Alpha 7ii", tag: "ILCE-7M2" },
    { key: "d5200", label: "Nikon D5200", tag: "NIKON D5200" },
    { key: "d5000", label: "Nikon D5000", tag: "NIKON D5000" },
    { key: "p300", label: "Nikon P300", tag: "COOLPIX P300" },
    { key: "a380", label: "Sony Alpha 380", tag: "DSLR-A380" },
    { key: "iphone16", label: "Apple iPhone 16", tag: "iPhone 16" },
    { key: "iphone13", label: "Apple iPhone 13", tag: "iPhone 13" },
    { key: "iphonexs", label: "Apple iPhone XS", tag: "iPhone XS" },
    { key: "iphone7", label: "Apple iPhone 7", tag: "iPhone 7" },
  ];

  export const lensOptions = [
    { key: "sony-fe-24-105mm", label: "Sony FE 24-105mm f/4 G OSS", tag: "FE 24-105mm F4 G OSS", type: "fullframe" },
    { key: "sony-fe-50mm-f2-5", label: "Sony FE 50mm f/2.5 G", tag: "FE 50mm F2.5 G", type: "fullframe" },
    { key: "sony-fe-50mm-f1-8", label: "Sony FE 50mm f/1.8", tag: "FE 50mm F1.8", type: "fullframe" },
    { key: "sigma-17-50mm", label: "Sigma 17-50mm f/2.8", tag: "17.0-50.0 mm f/2.8", type: "crop" },
    { key: "nikon-50mm-f1-8", label: "Nikon 50mm f/1.8", tag: "50.0 mm f/1.8", type: "crop" },
    { key: "nikon-18-55mm", label: "Nikon 18-55mm f/3.5-5.6", tag: "18.0-55.0 mm f/3.5-5.6", type: "crop" },
    { key: "nikon-18-105mm", label: "Nikon 18-105mm f/3.5-5.6", tag: "18.0-105.0 mm f/3.5-5.6", type: "crop" },
    { key: "nikon-55-200mm", label: "Nikon 55-200mm f/4.0-5.6", tag: "55.0-200.0 mm f/4.0-5.6", type: "crop" },
    { key: "nikon-70-300mm", label: "Nikon 70-300mm f/4.5-5.6", tag: "70.0-300.0 mm f/4.5-5.6", type: "crop" },
    { key: "tamron-70-300mm", label: "Tamron 70-300mm f/4.5-5.6", tag: "TAMRON SP 70-300mm F4-5.6 Di VC USD A005N", type: "crop" },
    { key: "iphone-13", label: "Apple iPhone 13 f/1.6", tag: "iPhone 13 back dual wide camera 5.1mm f/1.6", type: "iphone" },
    { key: "iphone-13-wide", label: "Apple iPhone 13 wide f/2.4", tag: "iPhone 13 back dual wide camera 1.54mm f/2.4", type: "iphone" },
    { key: "iphone-xs", label: "iPhone XS f/1.8", tag: "iPhone XS back dual camera 4.25mm f/1.8", type: "iphone" },
    { key: "iphone-xs-tele", label: "iPhone XS tele f/2.4", tag: "iPhone XS back dual camera 6mm f/2.4", type: "iphone" },
    { key: "iphone-7", label: "Apple iPhone 7 f/1.8", tag: "iPhone 7 back camera 3.99mm f/1.8", type: "iphone" },
  ];
  
  
  
  export const exposureTimeGroups = [
    { key: 'highspeed', label: 'High Speed (1/1000s or faster)', range: [0, 0.001] },
    { key: 'standard', label: 'Standard (1/1000s - 1/60s)', range: [0.001, 0.01667] },
    { key: 'slow', label: 'Slow (1/60s - 1s)', range: [0.01667, 1] },
    { key: 'long', label: 'Long Exposure (1s or longer)', range: [1, Infinity] },
  ];
  
  export const marks = [
    { value: 1.6, label: 'f1.6' },
    { value: 4, label: 'f4' },
    { value: 8, label: 'f8' },
    { value: 16, label: 'f16+' },
  ];
  
  export const focalLengthMarks = [
    { value: 14, label: '14' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 300, label: '300+' },
  ];
  