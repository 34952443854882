import React from 'react';
import styled from 'styled-components';

const FooterBar = styled.footer`
  bottom: 0;
  width: 100%;
  height: 60px;
  color: #fff;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  z-index: 1000;
  justify-content: flex-start;
  padding: 10px 40px;
`;

const Footer = () => {
  return (
    <FooterBar>
      <p>&copy; 2024 supernice pictures</p>
    </FooterBar>
  );
};

export default Footer;
