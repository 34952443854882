import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPicturesRequest } from '../redux/pictures/actions';

export const usePictures = () => {
  const dispatch = useDispatch();

  // Access the correct state slice
  const { pictures, loading, error } = useSelector((state) => state.pictures || { pictures: [], loading: false, error: null });

  useEffect(() => {
    // Dispatch the fetchPicturesRequest action if pictures are not loaded
    if (!pictures.length && !loading && !error) {
      dispatch(fetchPicturesRequest());
    }
  }, [dispatch, pictures, loading, error]);

  return { pictures, loading, error };
};
