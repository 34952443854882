import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import FsLightbox from 'fslightbox-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../Home.css';

// Function to sort the image data by DateTimeOriginal (newest first)
const sortByDateTimeOriginalDesc = (data) => {
  return data ? data.sort((a, b) => b.data.DateTimeOriginal - a.data.DateTimeOriginal) : [];
};

const FilteredImages = ({ tags = [], title = "" }) => {
  const [filteredImages, setFilteredImages] = useState([]);
  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(1);

  // Fetch the images from the Redux store
  const pictures = useSelector((state) => state.pictures.pictures || []);

  // Memoize sorted and filtered data to avoid re-calculating it on every render
  const sortedData = useMemo(() => sortByDateTimeOriginalDesc(pictures), [pictures]);

  // Filter the images based on the tags and limit the result to 15 images
  useEffect(() => {
    if (sortedData.length > 0) {
      const filteredData = tags.length === 0
        ? sortedData
        : sortedData.filter((item) => {
            return (
              item.Tags &&
              tags.some((tag) => item.Tags.toLowerCase().includes(tag.toLowerCase()))
            );
          });

      const limitedFilteredData = filteredData.slice(0, 11);

      // Only update state if the new filtered data is different
      if (JSON.stringify(limitedFilteredData) !== JSON.stringify(filteredImages)) {
        setFilteredImages(limitedFilteredData);
      }
    }
  }, [sortedData, tags]); // Removed filteredImages from dependency array

  // Handle lightbox toggle and product index
  const handleClick = (index) => {
    setProductIndex(index + 1);
    setToggler((prev) => !prev);
  };

  // Collection definition
  const collections = [
    { title: 'Night', path: 'night', tags: ['night'], featured: true },
    { title: 'Sun', path: 'sun', tags: ['sun'], featured: true },
    { title: 'Oktoberfest', path: 'oktoberfest', tags: ['oktoberfest']},
    { title: 'Skyline', path: 'skyline', tags: ['skyline'], featured: true },
    { title: 'Allianz Arena', path: 'allianzarena', tags: ['allianz arena'], featured: true },
    { title: 'Olympic Stadium & Park', path: 'olympicpark', tags: ['olympiastadion', 'olympia'], featured: true },
  ];

  // Function to find an image for each collection based on tags
  const findImageForCollection = (collectionTags) => {
    return pictures.find((picture) =>
      picture.Tags &&
      collectionTags.some((tag) => picture.Tags.toLowerCase().includes(tag.toLowerCase()))
    );
  };

  // Only include featured collections
  const featuredCollections = collections.filter((collection) => collection.featured);

  return (
    <div className="App">
      <Helmet>
        <title>{title} | supernice pictures</title>
        <meta name="description" content={`Pictures related to ${tags.join(', ')}`} />
      </Helmet>

      {/* Featured Images Section */}
      {filteredImages.length > 0 && (
        <div className="featured-item" onClick={() => handleClick(0)}>
          <LazyLoadImage
            src={`https://supernice-resized.s3.eu-central-1.amazonaws.com/large/${filteredImages[0].filename}`}
            alt={`Featured Image ${filteredImages[0].filename}`}
            loading="lazy"
            threshold="700"
            type="image"
          />
        </div>
      )}

      <div className="page-nav">
        <h2>Latest images</h2>
      </div>

      {/* Display only the first 15 filtered images */}
      <div className="flex-container">
        {filteredImages.length === 0 ? (
          <p>No images available.</p>
        ) : (
          filteredImages.map(({ filename, Title, data }, index) => (
            <div className="item" key={index} onClick={() => handleClick(index)}>
              <LazyLoadImage
                src={`https://supernice-resized.s3.eu-central-1.amazonaws.com/small/${filename}`}
                alt={`Image ${filename}`}
                loading="lazy"
                threshold="700"
                type="image"
              />
                <div className="info">
                <span>{Title}</span>
                <span>{`${data.FocalLengthIn35mmFormat}mm f/${data.FNumber}`}</span>
              </div>
            </div>
          ))
        )}
        <Link to="/feed" className="discover-more">
          <div className="content">
            <h3>Show all images</h3>
            <p>Discover all images</p>
          </div>
        </Link>   
      </div>

      <div className="page-nav">
        <h2>Featured Collections</h2>
      </div>

      {/* Featured Collections */}
      <div className="flex-container collection-container featured-collection-container">
        {featuredCollections.map((collection, index) => {
          const matchedImage = findImageForCollection(collection.tags);

          return (
            <Link to={`/collections/${collection.path}`} key={index} className="collection-item">
              <h3>{collection.title}</h3>
              {matchedImage ? (
                <LazyLoadImage
                  src={`https://supernice-resized.s3.eu-central-1.amazonaws.com/small/${matchedImage.filename}`}
                  alt={collection.title}
                  loading="lazy"
                  threshold="700"
                  type="image"
                />
              ) : (
                <p>No image available</p>
              )}
            </Link>
          );
        })}

          <Link to="/collections" className="discover-more">
          <div className="content">
            <h3>Show all collections</h3>
            <p>Discover the different collections</p>
          </div>
        </Link>   
      </div>

      {/* Banner for the Filters Page */}
      <div className="banner-container">
        <Link to="/explore" className="banner-item no-mobile ">
          <div className="banner-content">
            <h3>Explore Images</h3>
            <p>Find images using advanced filters and criteria</p>
          </div>
        </Link>
        <Link to="/random" className="banner-item">
          <div className="banner-content">
            <h3>Show Random Image</h3>
            <p>Discover a random image from the collections</p>
          </div>
        </Link>
      </div>

      {/* Lightbox for the images, limited to the same 15 images */}
      <FsLightbox
        toggler={toggler}
        sources={filteredImages.map(({ filename }) => `https://supernice-resized.s3.eu-central-1.amazonaws.com/large/${filename}`)}
        slide={productIndex}
        exitFullscreenOnClose={true}
      />
    </div>
  );
};

export default FilteredImages;
